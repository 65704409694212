/* Provide sufficient contrast against white background */
a {
  color: #0366d6;
}

code {
  color: #E01A76;
}

body{
  background-color: #1F1E25;
  color: white;
  font-family: "Roboto Condensed", serif;
  font-weight: normal;
  font-weight: 400;
  font-style: normal;
  font-optical-sizing: auto;
}

.navbar-light .navbar-nav .nav-link{
  color: white;
}

.navbar-light .navbar-nav .nav-link:focus, .navbar-light .navbar-nav .nav-link:hover{
  color: rgb(182, 182, 182);
}

.navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255, 255, 255)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
}

.btn-primary {
  color: #fff;
  background-color: #1b6ec2;
  border-color: #1861ac;
}

.container-fluid{
  padding: 0px;
}

.table{
  color: white;
}

.table-striped>tbody>tr:nth-child(odd)>td, 
.table-striped>tbody>tr:nth-child(odd)>th {
   color: rgb(180, 180, 180); 
 }
.console-nav-menu {
  background-color: black;
  height: 55px;
  padding-top: 8px;
  display: grid;
}

.telemetry-grid {
    padding-left: 20px;
    padding-right: 20px;
    display: grid;
    grid-template-columns: 129px 129px 129px 129px 129px minmax(10px, auto) minmax(0px, auto) minmax(0px, auto) 129px 129px 129px 129px;
    grid-gap: 30px;
    height: 40px;
    color: hsl(0, 0%, 100%);
    align-items: center;
}

.empty-grid {
    padding-left: 20px;
    padding-right: 20px;
    display: grid;
    grid-template-columns: 1fr 129px;
    grid-gap: 30px;
    height: 40px;
    color: hsl(0, 0%, 100%);
    align-items: center;
}

.tel-box {
    font-size: 18px;
}

.arm-box {
    
}

.name-box {
    padding-left: 20px;
}

.msg-box {
    grid-column-start: 3;
    grid-column-end: 6;
    background-color: #171717;
    padding-left: 20px;
    padding-right: 20px;
    width: 100%;
    height: 39px;
    overflow: hidden;
    align-items: center;
    display: grid;
    border-radius: 9px;
    cursor: pointer;
}

.mode-box {
    grid-column-start: 9;
    grid-column-end: 10;
}

.sat-box {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 5px;
}

.sat-box-icon {
    width: 35px;
    height: 28px;
    margin-left: auto;
}

.menu-box {
    display: grid;
    grid-template-columns: 2fr 3fr;
    grid-gap: 5px;
    align-items: center;
    cursor: pointer;
}

.menu-box-sep {
    height: 38px;
    width: 1px;
    margin-right: auto;
}

.menu-box-icon {
    width: 24px;
    height: 4px;
    margin-left: 8px
}

.logo-box {
    padding-left: 20px;
}


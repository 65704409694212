.console-blocks {
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 35px;
}

.console-drones {
    display: grid;
    grid-template-columns: 129px 129px 129px 129px 129px minmax(10px, auto) minmax(0px, auto) minmax(0px, auto) 129px 129px 129px 129px;
    grid-gap: 30px;
    height: 40px;
    color: hsl(0, 0%, 100%);
}

.console-modem-cards {
    grid-column-start: 1;
    grid-column-end: 3;
}

.offcanvas {
    background-color: #171717;
}

.offcanvas-end {
    width: 900px;
}
.status-text {
    grid-column-start: 3;
    grid-column-end: 6;
    width: 100%;
    overflow: hidden;
    align-items: center;
    display: grid;
    border-radius: 9px;
    height: fit-content;
}

.message {
    margin-bottom: 10px;
    cursor: pointer;
    background-color: #171717ea;
    border-radius: 9px;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 15px;
    padding-bottom: 15px;
}

.message-time {
    font-size: 16px;
    color: #9B9B9B;
    height: 22px;
}

.message-text {
    font-size: 18px;
    height: 22px;
}
.console-modem-card {
    margin-bottom: 10px;
    cursor: pointer;
    background-color: #171717ea;
    border-radius: 9px;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 15px;
    padding-bottom: 9px;
}

.console-modem-card:hover {
    background-color: #1f1f1fea;
}

.console-active-modem-card {
    outline: 1px solid #ffffff;
}

.console-modem-card-content {
    display: grid;
    grid-template-columns: 170px auto;
    grid-gap: 7px;
}

.modem-card-name {
    font-size: 16px;
    height: 22px;
}
.modem-card-imei {
    font-size: 16px;
    color: #9B9B9B;
    height: 22px;
}

.modem-card-arm {
    font-size: 18px;
}

.modem-card-update-icon {
    height: 30px;
}

.modem-card-online-icon {
    height: 30px;
    width: 30px;
    margin-left: auto;
}

.online-update {
    padding-top: 6px;
    display: grid;
    grid-template-columns: 30px auto;
}

.bind-card-icon {
    height: 10px;
    width: 10px;
    margin-left: auto;
    margin-right: auto;
}

.console-bind-card {
    margin-bottom: 10px;
    cursor: pointer;
    display: grid;
    background-color: #171717ea;
    border-radius: 9px;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 30px;
    padding-bottom: 30px;
}

.console-bind-card:hover {
    background-color: #1f1f1fea;
}